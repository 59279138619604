import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RefreshGridNotification {
  private onChangesEmit: EventEmitter<any> = new EventEmitter<any>();
  private onSilentChangesEmit: EventEmitter<any> = new EventEmitter<any>();

  emitSilentChanges(e) {
    this.onSilentChangesEmit.emit(e);
  }

  get onSilentChanges() {
    return this.onSilentChangesEmit;
  }

  emitChanges(e) {
    this.onChangesEmit.emit(e);
  }

  get onChanges() {
    return this.onChangesEmit;
  }
}
