import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { DownloadService } from './download.service';
import { FileAttributes } from '@ponylagoon/dto';
import { fileAttributes, photoSwipe, GraphqlService } from '../graphql';
import Fields from 'gql-query-builder/build/Fields';
import { FileSearch } from '../models/IFileResolver';
import { IFileUpdate } from '../models/IFileUpdate';
import { URL_ENDPOINTS } from '../models/URLEndpoints';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private graphQlService: GraphqlService,
    private downloadService: DownloadService, // private loadingService: LoadingService, // private appState: AppState, // private snackbar: SnackbarService, // private translate: TranslateService
  ) {}

  public downloadFile(file_id: string) {
    return this.downloadService
      .download(`${URL_ENDPOINTS.FILE_SVC}/${file_id}`)
      .subscribe();
  }

  getFiles(
    search: FileSearch,
    fields: Fields = [],
  ): Observable<FileAttributes[]> {
    return this.graphQlService.query<FileAttributes[]>({
      operation: 'getFiles',
      fields: [
        {
          operation: 'File',
          fields: [...fileAttributes, ...photoSwipe, ...fields],
          fragment: true,
        },
      ],
      variables: {
        search: { value: search, required: true, type: 'FileSearch' },
      },
    });
  }

  updateFile(file: IFileUpdate) {
    return this.graphQlService.mutate({
      operation: 'updateFile',
      fields: ['acknowledged'],
      variables: {
        file: {
          value: file,
          required: true,
          type: 'FileUpdate',
        },
      },
    });
  }

  markForDelete(file_id: string): Observable<any> {
    return this.updateFile({
      file_id,
      markForDelete: true,
    });
  }
}
